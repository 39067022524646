import React, { useState, useEffect } from "react";
import ReactDOMServer from 'react-dom/server';
import { Link } from "gatsby";
import Fancybox from "./fancybox";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { getProductUrl } from "../lib/helpers";

import * as styles from "../styles/components/gallery.module.css";

function Gallery({ items }) {

  // State for the list
  const [list, setList] = useState([...items.slice(0, 12)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(items.length > 12)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < items.length
      const nextResults = isMore
        ? items.slice(currentLength, currentLength + 12)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < items.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {list.map(({ _key, image, products }) => {
            return (
              <div className={cn("col-12 col-md-6 col-xl-4", styles.item)} key={_key}>
                {image && image.asset && (
                  <div className={styles.image}>
                    <Fancybox options={{ 
                      infinite: false,
                      dragToClose: false,
                      keyboard: false,
                      caption: function (fancybox, carousel, slide) {
                        return (
                          slide.caption
                        );
                      },
                      Toolbar: {
                        display: [
                          { id: "close", position: "right" },
                        ],
                      },
                      Thumbs: {
                        autoStart: false,
                      },
                    }}>
                      <a 
                        data-src={imageUrlFor(buildImageObj(image)).auto("format").url()} 
                        data-fancybox="gallery"
                        data-caption={
                          ReactDOMServer.renderToString(
                            <div className={styles.relatedProducts}>
                              <ul>
                                {products.map(({ product, _key }) => {
                                  return (
                                    <li key={_key}>
                                      <Link 
                                        to={getProductUrl(product.slug)} 
                                        title={product.title}
                                        className="view--more"
                                      >
                                        {product.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )
                        }
                      >
                        <img
                          src={imageUrlFor(buildImageObj(image))
                            .auto("format")
                            .size(815, 890)
                            .url()}
                          alt={image.alt}
                        />
                      </a>
                    </Fancybox>
                    {image.description && (<p>{image.description}</p>)}
                  </div>
                )}

                {products && products.length > 0 && (
                  <div className={styles.relatedProducts}>
                    <ul>
                      {products.map(({ product, _key }) => {
                        return (
                          <li key={_key}>
                            <Link to={getProductUrl(product.slug)} title={product.title} className="view--more">{product.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {hasMore && (
          <div className={styles.loadMore}>
            <button onClick={handleLoadMore} className="view--more">Load More</button>
          </div>
        )}
      </Container>
    </div>
  );
}

export default Gallery;
