import React from "react";
import { cn } from "../lib/helpers";
import Container from "./container";
import { Link } from 'react-scroll';
import Icon from "./icon";

import * as styles from "../styles/components/page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    notificationBar,
    description,
    anchors
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          <div className={cn("col-12 col-md-6", styles.colMain)}>
            <h1 className={styles.title}>{title}</h1>
            {description && (<p className={styles.desc}>{description}</p>)}
          </div>
          {anchors && anchors.length > 0 && (
            <div className={cn("col-12 col-md-6", styles.colAnchors)}>
              <ul className={styles.anchorList}>
                {anchors.map(anchor => {
                  return (
                    <li key={anchor._key}>
                      <Link 
                        to={anchor.anchorLink}
                        spy={false}
                        smooth={true}
                        hashSpy={false}
                        offset={(notificationBar && notificationBar.link && notificationBar.url) ? -150 : -100}
                        duration={800}
                        delay={0}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        spyThrottle={0}
                        className="anchor--link"
                      >
                        <Icon symbol="arrow-down" />
                        {anchor.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default PageHeader;
