import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import Gallery from "../components/gallery";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query GalleryPageQuery {
    gallery: sanityGallery {
      title
      description
      featuredImage {
        ...SanityImage
        alt
      }
      galleryImages {
        _key
        image {
          ...SanityImage
          alt
          description
        }
        products {
          _key
          product {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const GalleryPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const gallery = (data || {}).gallery;

  return (
    <Layout>
      <Seo
        title={gallery.title}
        description={gallery.description}
        image={gallery.featuredImage}
      />

      {gallery.title && <PageHeader title={gallery.title} />}
      {gallery.galleryImages && gallery.galleryImages.length > 0 && (<Gallery items={gallery.galleryImages} />)}
    </Layout>
  );
};

export default GalleryPage;
